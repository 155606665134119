<template>
  <div class="platformList">
    <app-list :opt="opt" @get="onGet" ref="appList"></app-list>
  </div>
</template>
<script>
let validRoomNo = (rule, value, callback) => {
  if (value == "" || value == undefined) {
    callback();
  } else {
    //仅支持输入数字、汉字，大小写字母，限制字符长度1~24个字符
    let reg = /^[a-zA-Z0-9\u4e00-\u9fa5]{1,24}$/;
    if (!reg.test(value)) {
      callback(new Error("房号格式不对"));
    } else {
      callback();
    }
  }
};
let validMAC = (rule, value, callback) => {
  if (value == "" || value == undefined) {
    callback();
  } else {
    //支持输入大小写字母，数字，限制1~32位字符
    let reg = /^[A-z0-9]{1,32}$/;
    if (!reg.test(value)) {
      callback(new Error("MAC格式不对"));
    } else {
      callback();
    }
  }
};
export default {
  data() {
    let _this = this;
    return {
      opt: {
        title: "",
        search: [
          {
              key: "apartmentId",
              label: "房源",
              type: "select",
              opt: {
                  list: [],
                  change(data, cb) {
                      _this.opt.search.forEach(item => {
                          if (item.key == 'buildingNo') {
                              cb({
                                  value: "",
                                  key: item.key,
                              })
                              item.opt.list = [];
                          }
                      })
                      _this.getBuildList(data);
                  }
              }
          },
          {
              key: "buildingNo",
              label: "楼栋",
              type: "select",
              opt: {
                  list: [],
                  parent: "apartmentId",
              }
          },
          {
              key: "roomNo",
              label: "房号",
              maxlength: 24,
              rules: [{
                  validator: validRoomNo,
                  trigger: ['blur']
              }]
          },
          // {
          //   key: "deviceId",
          //   label: "设备编号"
          // },
          {
            key: "btMac",
            label: "MAC",
            maxlength: 32,
              rules: [{
                validator: validMAC,
                trigger: ['blur']
              }]
          }
        ],
        columns: [
          // {
          //   label: "门锁编号",
          //   key: "lockId",
          //   on: row => {
          //     _this.cache.set("lockInfo", row);
          //     _this.$router.push("/main/lock/log/" + row.mac);
          //   }
          // },
           {
            label: "房号",
            key: "roomNo",
            on: row => {
              // _this.cache.set("separateAccessControl", row);
              _this.$router.push("/main/separateAccessControl/log/" + row.id); 
            }
          },
          {
            label: "MAC",
            key: "btMac"
          },
          // {
          //   label: "Wifi-MAC",
          //   key: "mac"
          // },
          { label: "设备类型", key: "type" },
          { label: "房源", key: "apartmentName" },
          { label: "楼栋", key: "buildingNo",},
          { label: "ip地址", key: "ip",},
          { label: "最近一次开门禁时间", key: "lastOpenDoorTime" },
          {
            label: "门锁状态",
            key: "lockStateStr"
          }
        ]
      }
    };
  },
  created() {
    console.log("platformList created!!");
    this.$nextTick(() => {
        this.$refs.appList.onRefresh();
    })
    this.getApartmentList();
  },
   activated() {
    this.getApartmentList();
  },
  methods: {
    onGet(opt) {
      let dto = {
        pageNum: opt.skip,
        pageSize: opt.limit,
        ...opt.searchForm
      };
      this.post("device-service/w7/page/query", dto, {
        isUseResponse: true
      }).then(res => {
        for (let i = 0; i < res.data.data.length; i++) {
          let item = res.data.data[i];
          item.type = ["蓝牙网关", "W7","L2蓝牙公钥",'','W8','','','W81'][item.type-1];
          item.lockStateStr = ["未绑定", "已绑定"][item.bindLockState];
        } 
        opt.cb(res.data);
      });
    },
    //获取房源列表
    getApartmentList() {
      this.post('/landlord-service/house/v1/apartment/query/select-all').then(res => {
            res = JSON.parse(JSON.stringify(res).replace(/name/g, "label"));
            res = JSON.parse(JSON.stringify(res).replace(/id/g, "value"));
            this.opt.search.forEach(item => {
                if (item.key == 'apartmentId') {
                    item.opt.list = res;
                }
            })
        })
    },
    // 获取楼栋
    getBuildList(data) {
        if (!data) {
            return
        }
        let dto = {
            apartmentId: data
        }
        this.post('/landlord-service/building/list', dto).then(res => {
            res = JSON.parse(JSON.stringify(res).replace(/buildName/g, "label"));
            res = JSON.parse(JSON.stringify(res).replace(/buildCode/g, "value"));
            this.opt.search.forEach(item => {
                if (item.key == 'buildingNo') {
                    item.opt.list = res;
                }
            })
        })
    },
  },
  
};
</script>
<style lang="scss" scoped>
    .platformList {
        height: calc(100% - 126px);
    }
</style>
