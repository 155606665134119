<template>
  <div class="separateAccessControl-list">
    <div class="title">
      <app-header :title="title" :isShowBack="false"></app-header>
    </div>
    <div style="padding-bottom: 30px;display:inline-block;">
      <span style="padding-right:20px;">使用场景</span>
      <el-select style="width: 300px;display: inline-block;" v-model="sceneType"
        @change="changeSceneType(sceneType)">
        <el-option v-for="item in sceneTypeList" :key="item.value" :label="item.label" :value="item.value">
        </el-option>
      </el-select>
    </div>
    <PlatformList v-if="sceneType == 1"></PlatformList>
    <PersonalList v-if="sceneType == 2"></PersonalList>

  </div>
</template>
<script>
  import PlatformList from './platformList';
  import PersonalList from './personalList';
  
  export default {
    components: {
      PlatformList,
      PersonalList,
    },
    data() {    
      return {
        title: "分离式门禁列表",
        sceneType: 1,
        sceneTypeList: [{
            label: '平台',
            value: 1,
          },
          {
            label: '个人',
            value: 2,
          }],
      };
    }, 
    activated() {
      console.log('分离式门禁列表activated')
      let sceneType = this.cache.get('sceneType');
      if (!sceneType) {
        this.sceneType = 1;
        this.cache.set('sceneType', this.sceneType);
      }
    },
    created() {
      console.log('分离式门禁列表created')
      let sceneType = this.cache.get('sceneType');
      if (!sceneType) {
        this.sceneType = 1;
        this.cache.set('sceneType', this.sceneType);
      }
    },
    mounted() {
      console.log('分离式门禁列表mounted')
    },
    deactivated() {
      let pathList = this.$route.path.split('/')
      if (pathList[2] != 'separateAccessControl') {
        this.cache.del('sceneType');
      }
    },
    methods: {
      changeSceneType(val) {
        this.sceneType = val;
        this.cache.set('sceneType', val);
      },
    }
  };
</script>
<style lang="scss" scoped>

</style>