var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "separateAccessControl-list" },
    [
      _c(
        "div",
        { staticClass: "title" },
        [_c("app-header", { attrs: { title: _vm.title, isShowBack: false } })],
        1
      ),
      _c(
        "div",
        { staticStyle: { "padding-bottom": "30px", display: "inline-block" } },
        [
          _c("span", { staticStyle: { "padding-right": "20px" } }, [
            _vm._v("使用场景")
          ]),
          _c(
            "el-select",
            {
              staticStyle: { width: "300px", display: "inline-block" },
              on: {
                change: function($event) {
                  _vm.changeSceneType(_vm.sceneType)
                }
              },
              model: {
                value: _vm.sceneType,
                callback: function($$v) {
                  _vm.sceneType = $$v
                },
                expression: "sceneType"
              }
            },
            _vm._l(_vm.sceneTypeList, function(item) {
              return _c("el-option", {
                key: item.value,
                attrs: { label: item.label, value: item.value }
              })
            }),
            1
          )
        ],
        1
      ),
      _vm.sceneType == 1 ? _c("PlatformList") : _vm._e(),
      _vm.sceneType == 2 ? _c("PersonalList") : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }