<template>
  <div class="personalList">
    <app-list :opt="opt" @get="onGet" ref="appList"></app-list>
  </div>
</template>
<script>
let validMAC = (rule, value, callback) => {
  if (value == "" || value == undefined) {
    callback();
  } else {
    //支持输入大小写字母，数字，限制1~32位字符
    let reg = /^[A-z0-9]{1,32}$/;
    if (!reg.test(value)) {
      callback(new Error("MAC格式不对"));
    } else {
      callback();
    }
  }
};
export default {
  data() {
    let _this = this;
    return {
      opt: {
        title: "",
        search: [
          {
            key: "mac",
            label: "MAC",
            maxlength: 32,
            rules: [
              {
                validator: validMAC,
                trigger: ["blur"],
              },
            ],
          },
          {
            key: "userName",
            label: "管理员",
          },
          {
            key: "userPhone",
            label: "管理员手机号码",
          },
        ],
        columns: [
          {
            label: "MAC",
            key: "mac",
            on: (row) => {
              _this.$router.push(
                "/main/separateAccessControl/personalDeviceDetail/" + row.deviceId
              );
            },
          },
          { label: "设备型号", key: "model" },
          { label: "管理员姓名", key: "userName" },
          { label: "管理员手机号码", key: "userPhone" },
          { label: "添加时间", key: "createTime" },
        ],
      },
    };
  },
  created() {
    console.log("personalList created!!");
    this.$nextTick(() => {
      this.$refs.appList.onRefresh();
    });
  },
  activated() {},
  methods: {
    onGet(opt) {
      let dto = {
        current: opt.skip,
        pageSize: opt.limit,
        category: "entrance", //业务分类 entrance-门禁
        grantType: 1,
        ...opt.searchForm,
      };
      this.post("homelock-service/user-device/web/api/v1/bind-record", dto, {
        isUseResponse: true,
      }).then((res) => {
        if (res.data.code == 0) {
          if(res.data.data && res.data.data.length > 0) {
            res.data.data.forEach(item => {
              item.model = item.model || "--";
              item.userName = item.userName || "--";
              item.userPhone = item.userPhone || "--";
              item.createTime = item.createTime || "--";
            })
          }
          opt.cb(res.data);
        }
      }).catch(err =>{
        console.log('------err:',err)
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.personalList {
  height: calc(100% - 126px);
}
</style>
